@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/* CSS Document */
/*====================================================================

	helper.css

=====================================================================*/

/* display */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .d#{$infix}-none {
      display: none !important;
    }
    .d#{$infix}-inline {
      display: inline !important;
    }
    .d#{$infix}-inline-block {
      display: inline-block !important;
    }
    .d#{$infix}-block {
      display: block !important;
    }
    .d#{$infix}-table {
      display: table !important;
    }
    .d#{$infix}-table-row {
      display: table-row !important;
    }
    .d#{$infix}-table-cell {
      display: table-cell !important;
    }
    .d#{$infix}-flex {
      display: flex !important;
    }
    .d#{$infix}-inline-flex {
      display: inline-flex !important;
    }
  }
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-less($breakpoint, $grid-breakpoints);
    .d#{$infix}-less-none {
      display: none !important;
    }
    .d#{$infix}-less-inline {
      display: inline !important;
    }
    .d#{$infix}-less-inline-block {
      display: inline-block !important;
    }
    .d#{$infix}-less-block {
      display: block !important;
    }
    .d#{$infix}-less-table {
      display: table !important;
    }
    .d#{$infix}-less-table-row {
      display: table-row !important;
    }
    .d#{$infix}-less-table-cell {
      display: table-cell !important;
    }
    .d#{$infix}-less-flex {
      display: flex !important;
    }
    .d#{$infix}-less-inline-flex {
      display: inline-flex !important;
    }
  }
}

/* img */
a.light-box {
  @include light-box;
}
a.img-scale {
  @include img-scale;
}

//アスペクト比固定用
.img-aspect {
  @include aspect-img;
}

/* ofi */
.ofi {
  &-contain {
    @include ofi-contain;
  }
  &-cover {
    @include ofi-cover;
  }
}

.of-hidden {
  overflow: hidden;
}

/* color */
.cl-- {
  &red {
    color: $red;
  }
  &blue {
    color: $blue;
  }
}
.required {
  display: inline-block;
  padding: .1em 1em;
  color:$white;
  background: $red;
  font-size: 0.875em;
}
/* font */
//font size
.fs- {
  &s {
    font-size: 0.875em;
  }
  &m {
    font-size: 1.14em;
  }
  &l {
    font-size: 1.25em;
  }
  &xl {
    font-size: 1.5em;
  }
  &-14 {
    @include f-size(14);
  }
}

.fw- {
  &400 {
    @include f-w(400);
  }
  &500 {
    @include f-w(500);
  }
  &600 {
    @include f-w(600);
  }
  &700 {
    @include f-w(700);
  }
}

.f- {
  &font01 {
    @include f-family(font01);
  }
  &font02 {
    @include f-family(font02);
  }
  &font03 {
    @include f-family(font03);
  }
}


/* txt-align */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .text#{$infix}-left {
      text-align: left !important;
    }
    .text#{$infix}-right {
      text-align: right !important;
    }
    .text#{$infix}-center {
      text-align: center !important;
    }
  }
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-less($breakpoint, $grid-breakpoints);
    .text {
      .text#{$infix}-less-left {
        text-align: left !important;
      }
      .text#{$infix}-less-right {
        text-align: right !important;
      }
      .text#{$infix}-less-center {
        text-align: center !important;
      }
    }
  }
}
.clearfix {
  @include clearfix;
}

/* botton */

/* default
btn_normal(テキストカラー:$white,バックグランドカラー:$main_c,ボーダーカラー:$main_c,$テキストホバーカラー:$white,バックグランドホバーカラー:$point_c,ボーダーホバーカラー:$point_c)
btn_arrow(テキストカラー:$white,バックグランドカラー:$main_c,ボーダーカラー:$main_c,$テキストホバーカラー:$white,バックグランドホバーカラー:$point_c,ボーダーホバーカラー:$point_c)
btn_line(テキストカラー:$main_c,バックグランドカラー:$white,ボーダーカラー:$main_c,$テキストホバーカラー:$white,バックグランドホバーカラー:$point_c,ボーダーホバーカラー:$black)
*/

.common-btn {
  &--main {
    @include btn_arrow($white,$main_c,$main_c,$white,$white,$hover_c,$hover_c,$white);
  }
  &--sub {
    @include btn_arrow($white,$sub_c,$sub_c,$white,$white,$hover_sub,$hover_sub,$white);
  }
  &--black {
    @include btn_arrow($black,$white,$black,$black,$white,$black,$d-gray,$white);
  }
  &--brown {
    @include btn_arrow($white,$other_c1,$other_c1,$white,$white,$hover_brown,$hover_brown,$white);
  }
  &--white {
    @include btn_arrow($other_c2,$white,$other_c1,$other_c1,$white,$other_c1,$other_c1,$white);
  }
}

/* margin */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length;
        }
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .m-a {
      &#{$infix} {
        @include m-a;
      }
    }
  }
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-less($breakpoint, $grid-breakpoints);
    .m-a {
      &#{$infix}-less {
        @include m-a;
      }
    }
  }
}

/* max-width */
.w- {
  &280 {
    max-width: 280px;
    @include media-breakpoint-up(full) {
      max-width: floor-decimal(calc(280 / 1920), 4) * 100vw;
    }
  }
  &320 {
    max-width: 320px;
    @include media-breakpoint-up(full) {
      max-width: floor-decimal(calc(320 / 1920), 4) * 100vw;
    }
  }
  &360 {
    max-width: 360px;
    @include media-breakpoint-up(full) {
      max-width: floor-decimal(calc(360 / 1920), 4) * 100vw;
    }
  }
  &480 {
    max-width: 480px;
    @include media-breakpoint-up(full) {
      max-width: floor-decimal(calc(480 / 1920), 4) * 100vw;
    }
  }
  &800 {
    max-width: 800px;
    @include media-breakpoint-up(full) {
      max-width: floor-decimal(calc(800 / 1920), 4) * 100vw;
    }
  }
  &960 {
    max-width: 960px;
    @include media-breakpoint-up(full) {
      max-width: floor-decimal(calc(960 / 1920), 4) * 100vw;
    }
  }
  &100p {
    width: 100%;
  }
}
/* height */

.h-{
  &100{
    height: 100%;
  }
}

/* flexbox */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .flex#{$infix}-row {
      flex-direction: row !important;
    }
    .flex#{$infix}-column {
      flex-direction: column !important;
    }
    .flex#{$infix}-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex#{$infix}-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex#{$infix}-wrap {
      flex-wrap: wrap !important;
    }
    .flex#{$infix}-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex#{$infix}-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex#{$infix}-fill {
      flex: 1 1 auto !important;
    }
    .flex#{$infix}-grow-0 {
      flex-grow: 0 !important;
    }
    .flex#{$infix}-grow-1 {
      flex-grow: 1 !important;
    }
    .flex#{$infix}-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex#{$infix}-shrink-1 {
      flex-shrink: 1 !important;
    }

    .justify-content#{$infix}-start {
      justify-content: flex-start !important;
    }
    .justify-content#{$infix}-end {
      justify-content: flex-end !important;
    }
    .justify-content#{$infix}-center {
      justify-content: center !important;
    }
    .justify-content#{$infix}-between {
      justify-content: space-between !important;
    }
    .justify-content#{$infix}-around {
      justify-content: space-around !important;
    }

    .align-items#{$infix}-start {
      align-items: flex-start !important;
    }
    .align-items#{$infix}-end {
      align-items: flex-end !important;
    }
    .align-items#{$infix}-center {
      align-items: center !important;
    }
    .align-items#{$infix}-baseline {
      align-items: baseline !important;
    }
    .align-items#{$infix}-stretch {
      align-items: stretch !important;
    }

    .align-content#{$infix}-start {
      align-content: flex-start !important;
    }
    .align-content#{$infix}-end {
      align-content: flex-end !important;
    }
    .align-content#{$infix}-center {
      align-content: center !important;
    }
    .align-content#{$infix}-between {
      align-content: space-between !important;
    }
    .align-content#{$infix}-around {
      align-content: space-around !important;
    }
    .align-content#{$infix}-stretch {
      align-content: stretch !important;
    }

    .align-self#{$infix}-auto {
      align-self: auto !important;
    }
    .align-self#{$infix}-start {
      align-self: flex-start !important;
    }
    .align-self#{$infix}-end {
      align-self: flex-end !important;
    }
    .align-self#{$infix}-center {
      align-self: center !important;
    }
    .align-self#{$infix}-baseline {
      align-self: baseline !important;
    }
    .align-self#{$infix}-stretch {
      align-self: stretch !important;
    }
  }
}

// glid
@include make-grid-columns();
.container {
  @include make-container();
  @include make-container-max-widths();
}
.container-fluid {
  @include make-container();
}
.row {
  @include make-row();
  > [class*="col-"] {
    img {
      width: 100%;
    }
  }
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

