//===================================================
//	color
//===================================================
// メインカラー
$main_c:    #DA697F;//メインカラー
$sub_c:     #3AA6B9;//サブカラー
$point_c:   #e05872;//ポイントカラー
$hover_c:   #eb234a;//ホバーカラー
$hover_sub: #0e85b0;//ホバーカラー sub
$hover_brown: #6b4e3c;//ホバーカラー brown
$txt_c:     #202020;//テキストカラー
$link_c:    $main_c;//リンク基本カラー

// other color
$other_c1:  #C19F8A;//アザーカラー1
$other_c2:  #5A5141;//アザーカラー2
$other_c3:  #5B493D;//アザーカラー3
$other_c4:  #EE8B9F;//アザーカラー4
$other_c5:  #FFB7C5;//アザーカラー5
$other_c6:  #FFD0D5;//アザーカラー6
$other_c7:  #988E88;//アザーカラー7
$other_c8:  #EAE0DA;//アザーカラー8
$other_c9:  #F8B320;//アザーカラー99

// backgraound
$bg_c1:   #FAF6F4;//バックグラウンドカラー1
$bg_c2:   #F3FAFB;//バックグラウンドカラー2
$bg_c3:   #FCF9F8;//バックグラウンドカラー3
$bg_c4:   #D7F1FB;//バックグラウンドカラー4
$bg_c5:   #F2F7FF;//バックグラウンドカラー5
$bg_c6:   #F7F5EB;//バックグラウンドカラー6


// mono tone
$white:     #ffffff;   //ホワイト
$o-white:   #fdfdfd;   //オフホワイト
$p-gray:    #f6f6f6;   //ペールグレー
$l-gray:    #E5E9EC;   //ライトグレー
$m-gray:    #C9CED2;   //ミディアムグレー
$gray:      #979DA4;   //グレー
$dl-gray:   #6F757B;   //ダルグレー
$d-gray:    #4B4D4E;   //ダークグレー
$b-gray:    #232729;   //ブラックグレー
$black:     #000000;   //ブラック

// color tone
$red:       #D93838;//レッド
$red2:      #F24949;//レッド2
$orange:    #ff8b2d;//オレンジ
$yellow:    #ffcf27;//イエロー
$y-green:   #01a63d;//イエローグリーン
$green:     #5d9e36;//グリーン
$s-blue:    #78CAF2;//スカイブルー
$l-blue:    #578FE4;//ライトブルー
$blue:      #2b74e0;//ブル-
$violet:    #425c93;//バイオレット
$purple:    #5c0c7a;//パープル
$pink:      #ca6a51;//ピンク


// loader color
$bg_loader: $main_c;//ローダー時の背景色、及びページ遷移時の色